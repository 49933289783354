import React, { useEffect, Suspense } from "react";
import { useRoutes } from "hookrouter";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { navigate } from "hookrouter";
const Home = React.lazy(() => import("./components/Home/Home"));
const Event = React.lazy(() => import("./components/Event"));
const AllEvents = React.lazy(() => import("./components/AllEvents"));
const BehindUs = React.lazy(() => import("./components/Team/BehindUs"));
const Achievements = React.lazy(() => import("./components/Achievements"));
const routes = {
  "/": () => <Home />,
  "/allEvents": () => <AllEvents />,
  "/allEvents/:year": ({ year }) => <AllEvents year={year} />,
  "/behindUs": () => <BehindUs />,
  "/behindUs/:year": ({ year }) => <BehindUs year={year} />,
  "/achievements": () => <Achievements />,
  "/events/id/:id": ({ id }) => <Event id={id} docId={false} />,
  "/events/_id/:id": ({ id }) => <Event id={id} docId={true} />,
};

function App() {
  const routeResult = useRoutes(routes);
  if (!routeResult) navigate("/");
  useEffect(() => window.scrollTo(0, 0));
  return (
    <div className="bg-white w-full flex-col relative min-h-screen font-sans">
      <div className="w-full relative px-1">
        <NavBar />
      </div>
      <div className="min-h-screen font-medium relative">
        <Suspense
          fallback={
            <div className=" px-2 flex rounded-b-xl -mt-3 md:mt-3">
              <div class="bar w-full mx-auto max-w-7xl rounded-b-xl z-40 relative">
                <div></div>
              </div>
            </div>
          }
        >
          {routeResult}
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default App;
